'use client'

import { usePathname } from 'next/navigation'
import { useState, useMemo } from 'react'
import { Button, Card, List } from 'flowbite-react'
import { Euro } from 'flowbite-react-icons/outline'
// import Link from 'next/link'
import { Props } from '.'
import { FormattedNumericInput } from '@/components/FormattedNumericInput'
import { formatNumber } from '@/lib/utils'

export const ProfitAllowanceCalculator = ({ appointmentsPage }: Props) => {
  const pathname = usePathname()
  const [profit, setProfit] = useState(35000)
  const [showResult, setShowResult] = useState(false)

  const { allowance, basicAllowance } = useMemo(
    () => calculateFreeProfitAllowance(profit),
    [profit],
  )
  const calculatorDetailsMessage = `Gewinnfreibetragsrechner Details:
-------------------------------
Gewinnfreibetrag gesamt: ${allowance}
davon Grundfreibetrag: ${formatNumber(basicAllowance)}
Ihr Gewinn: ${profit}
Ihr Gewinn (abzüglich Gewinnfreibetrag): ${profit - allowance}
URL: ${pathname}
-------------------------------`

  const appointmentBody = `Sehr geehrte Damen und Herren,

bitte um Kontaktaufnahme für einen Beratungstermin.

${calculatorDetailsMessage}

Mit freundlichen Grüßen`

  return (
    <>
      <Card className="mx-auto max-w-[80rem] [&>div]:p-0">
        <div className="flex-row gap-4 md:flex">
          <div className="space-y-6 p-6 md:w-1/2">
            <h2 className="text-2xl font-bold">Gewinnfreibetragsrechner</h2>
            <div className="space-y-6">
              <h3 className="font-semibold">Ihr Gewinn</h3>
              <div className="gap-8 xl:flex">
                <FormattedNumericInput
                  className="size max-w-80"
                  color="white"
                  icon={Euro}
                  sizing="lg"
                  value={profit}
                  setValue={setProfit}
                  min={0}
                  max={Infinity}
                  name="profit"
                />
                <div className="mt-6 flex-1 text-sm xl:mt-0">
                  Geben Sie den Gesamtgewinn Ihres Unternehmens aus dem
                  vorherigen Geschäftsjahr ein. Dieser Betrag ist die Basis für
                  die Berechnung des Gewinnfreibetrags.
                </div>
              </div>
              <Button onClick={() => setShowResult(true)}>
                Jetzt berechnen
              </Button>
            </div>
          </div>
          <div className="space-y-6 bg-gray-50 p-6 md:w-1/2">
            {!showResult ? (
              <>
                <h3 className="text-2xl font-semibold">
                  Gewinnfreibetrag allgemein
                </h3>
                <List className="">
                  <List.Item>Grundfreibetrag bis zu 33.000 Euro: 15%</List.Item>
                  <List.Item>für die nächsten 145.000 Euro: 13%</List.Item>
                  <List.Item>für die nächsten 175.000 Euro: 7% </List.Item>
                  <List.Item>für die nächsten 230.000 Euro: 4,5%</List.Item>
                  <List.Item>
                    ab 583.000 Euro: kein weiterer Gewinnfreibetrag
                  </List.Item>
                </List>
                <p>
                  Der maximale investitionsbedingte Gewinnfreibetrag beträgt
                  damit € 46.400,-.
                </p>
              </>
            ) : (
              <>
                <h3 className="text-2xl font-semibold">Ihr Gewinnfreibetrag</h3>
                <p className="flex items-center justify-between">
                  <span>Gewinnfreibetrag</span>
                  <strong className="whitespace-nowrap text-3xl">
                    € {formatNumber(allowance, 0)},-
                  </strong>
                </p>
                <p className="flex justify-between">
                  <span>Davon sind der Grundfreibetrag</span>
                  <span className="whitespace-nowrap">
                    € {formatNumber(basicAllowance, 0)},-
                  </span>
                </p>
                <p className="flex justify-between">
                  <span>Ihr Gewinn </span>
                  <span className="whitespace-nowrap">
                    € {formatNumber(profit, 0)},-
                  </span>
                </p>
                <p className="flex justify-between">
                  <span>Ihr Gewinn (abzüglich Gewinnfreibetrag) </span>
                  <span className="whitespace-nowrap">
                    € {formatNumber(profit - allowance, 0)},-
                  </span>
                </p>
                <Button
                  type="submit"
                  as={'a'}
                  href={`${appointmentsPage?.path}?message=${encodeURIComponent(appointmentBody)}&originURL=${encodeURIComponent(pathname)}`}
                >
                  Beratungstermin vereinbaren
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>
      <div className="mt-6 text-center text-gray-600">
        Hierbei handelt es sich um eine MARKETINGMITTEILUNG der Medieninhaberin.
        Die Berechnung erfolgt auf Basis Ihrer Eingaben, sie dient als erste
        Orientierung und ist ohne Gewähr.
      </div>
    </>
  )
}
// Source: https://www.usp.gv.at/steuern-finanzen/steuerliche-gewinnermittlung/weitere-informationen-zur-steuerlichen-gewinnermittlung/betriebseinnahmen-und-ausgaben/gewinnfreibetrag.html
// Alowance: Gewinnfreibetrag
// Basic Allowance: Grundfreibetrag
export function calculateFreeProfitAllowance(profit: number) {
  const result = { allowance: 0, basicAllowance: 0 }
  if (!profit || profit < 0) return result
  const tiers = [
    { limit: 33_000, rate: 0.15 },
    { limit: 145_000, rate: 0.13 },
    { limit: 175_000, rate: 0.07 },
    { limit: 230_000, rate: 0.045 },
  ] as const
  const basicTierLimit = tiers[0].limit

  let remainingProfit = profit

  for (const tier of tiers) {
    if (remainingProfit <= 0) break
    const applicableProfit = Math.min(remainingProfit, tier.limit)
    const tierAllowance = applicableProfit * tier.rate
    result.allowance += tierAllowance
    if (tier.limit === basicTierLimit) {
      result.basicAllowance = tierAllowance
    }
    remainingProfit -= applicableProfit
  }
  // sanity check
  result.allowance = Math.min(result.allowance, 46_400)

  return result
}
